import React from 'react';
import { useEffect, useState } from "react";
import { motion, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './Landing.scss';
import { Mobile } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';

import googleDownloadIcon from 'assets/svg/google-download-icon.svg';
import appleDownloadIcon from 'assets/svg/apple-download-icon.svg';
import huaweiDownloadIcon from 'assets/svg/huawei-download-icon.svg';

const PowerBankSolution = (props) => {

  const controls = useAnimation();
  const navigate = useNavigate();

  const navVariants = {
    initial: { y: '50px', opacity: 0 },
    exit: { opacity: 0 }
  };


  const goTo = (link) => {
    navigate(link);
  };

  useEffect(() => {
    controls.start((i) => ({
      y: 0,
      opacity: 1,
      duration: 0.5,
      transition: { delay: 3+i * 0.5 }
    }));
  }, [controls]);

  return (
    <div className='body'>
    <div className="container">
    <Mobile>
          <div className="home_carousel__cta">
            <motion.a
              variants={navVariants}
              initial="initial"
              animate={controls}
              whileHover="whileHover"
              custom={0}
              className="home_carousel__cta__btn"
              href="https://play.google.com/store/apps/details?id=co.za.adoozypower"
              target="_blank"
              >
              <img className="home_carousel__cta__btn__image" src={googleDownloadIcon} width="" height="" alt="Get it on Google Play" />
            </motion.a>
            <motion.a
              variants={navVariants}
              initial="initial"
              animate={controls}
              whileHover="whileHover"
              custom={1}
              className="home_carousel__cta__btn"
              href="https://apps.apple.com/us/app/adoozy/id1584497287"
              target="_blank"
            >
              <img className="home_carousel__cta__btn__image" src={appleDownloadIcon} width="" height="" alt="Get it on App Store" />
            </motion.a>
            <motion.a
              variants={navVariants}
              initial="initial"
              animate={controls}
              whileHover="whileHover"
              custom={1}
              className="home_carousel__cta__btn"
              href="https://apps.apple.com/us/app/adoozy/id1584497287"
              target="_blank"
            >
              <img className="home_carousel__cta__btn__image" src={huaweiDownloadIcon} width="" height="" alt="Get it on App Store" />
            </motion.a>
          </div>
        </Mobile>
      <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeOut", duration: 3 }}>
      <h1 className="Textheader">The Preferred Power Bank Solution</h1>
      <p className="subHeader">I'm interested in using the service</p>
      <div className="optionsContainer">
        {
          props.sendData.land.map((item, index) =>
            <div className="option" onClick={e => goTo(item.link)} key={index}>
              <img 
                src={item.image.sizes.large}
                alt={item.label}
                className="image"
              />
              <div className="optionText">{item.label}</div>
              <div className="arrowButton">
                  <img src={item.icon.sizes.large}/>
              </div>
            </div>
          )
        }
      </div>
      </motion.div>
    </div>
    </div>
  );
};
  
export default PowerBankSolution;
