import { useEffect, useState, useRef } from "react";
import { motion, useAnimation } from 'framer-motion';
import { Mobile } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';

import './HomeCarousel.scss';

import HomeCarouselListItem from './HomeCarouselListItem';
import HomeCarouselNavItem from './HomeCarouselNavItem';

import rightArrowIcon from 'assets/svg/right-arrow-icon.svg';
import googleDownloadIcon from 'assets/svg/google-download-icon.svg';
import appleDownloadIcon from 'assets/svg/apple-download-icon.svg';
import huaweiDownloadIcon from 'assets/svg/huawei-download-icon.svg';

import btnArrowIcon from "../../assets/svg/scroll-btn-down-arrow-icon.svg";

const transition = {
  duration: 1,
  delay: 1,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const navVariants = {
  initial: { y: '50px', opacity: 0 },
  exit: { opacity: 0 }
};

export default function HomeCarousel(props) {
  const [state, setState] = useState({ active_slide: 0 });
  const controls = useAnimation();
  const ref = useRef();
  const currentPosition = window.pageYOffset
  const scrollTo = (e) => {
    window.scrollBy({
      top: currentPosition + window.innerHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    controls.start((i) => ({
      y: 0,
      opacity: 1,
      duration: 0.5,
      transition: { delay: 3+i * 0.5 }
    }));
  }, [controls]);

  useEffect(() => {
    const timer = setInterval(() => {
      if(props.slides.length-1 <= state.active_slide) {
        setState({ active_slide: 0 });
      } else {
        setState({ active_slide: state.active_slide+1 });
      }
    }, 7000);

    return () => {
      clearInterval(timer);
    };
  }, [state]);

  useEffect(() => {
    if(props.carouselPosition !== null) {
      setState({ active_slide: props.carouselPosition });
    }
  }, [props.carouselPosition]);

  return (
    <div className="home_carousel">
      <ul className="home_carousel__list">
        {props.slides.map((slide, index) => <HomeCarouselListItem key={index}  is_active={state.active_slide === index} video={slide.video[0]} videomobile={slide.videomobile[0]} first_line={slide.first_line} second_line={slide.second_line} to={slide.link} action={props.hasOwnProperty(slide.action) ? props[slide.action] : null} />)}
      </ul>
      <ul className="home_carousel__nav">
        { props.slides.map((slide, index) => <HomeCarouselNavItem key={index} index={index} is_active={state.active_slide === index} to={slide.link} name={slide.title} sections={props.sections} action={props.hasOwnProperty(slide.action) ? props[slide.action] : null} />)}
      </ul>
      
      
      <Mobile>
        <div className="home_carousel__cta">
          <motion.a
            variants={navVariants}
            initial="initial"
            animate={controls}
            whileHover="whileHover"
            custom={0}
            className="home_carousel__cta__btn"
            href="https://play.google.com/store/apps/details?id=co.za.adoozypower"
            target="_blank"
            >
            <img className="home_carousel__cta__btn__image" src={googleDownloadIcon} width="" height="" alt="Get it on Google Play" />
          </motion.a>
          <motion.a
            variants={navVariants}
            initial="initial"
            animate={controls}
            whileHover="whileHover"
            custom={1}
            className="home_carousel__cta__btn"
            href="https://apps.apple.com/us/app/adoozy/id1584497287"
            target="_blank"
          >
            <img className="home_carousel__cta__btn__image" src={appleDownloadIcon} width="" height="" alt="Get it on App Store" />
          </motion.a>
          <motion.a
            variants={navVariants}
            initial="initial"
            animate={controls}
            whileHover="whileHover"
            custom={1}
            className="home_carousel__cta__btn"
            href="https://apps.apple.com/us/app/adoozy/id1584497287"
            target="_blank"
          >
            <img className="home_carousel__cta__btn__image" src={huaweiDownloadIcon} width="" height="" alt="Get it on App Store" />
          </motion.a>
        </div>
        <div className="home_carousel__scrollMain">
      <motion.button
        initial={{ y: 0 }}
        animate={{ y: '5%' }}
        transition={{
          duration: 0.6,
          delay: 4.5,
          ease: [0.43, 0.13, 0.23, 0.96],
          repeat: 3,
          repeatType: 'mirror'
        }}
        className="home_carousel__scroll_btn"
        onClick={(e) => scrollTo(e)}
      >
        <motion.span
          initial={{ scaleY: 0 }}
          animate={{ scaleY: 1 }}
          exit={{ scaleY: 0 }}
          transition={{ duration: 0.4, delay: 2 }}
          className="home_carousel__scroll_btn__top_motif"
        >
          <span className="home_carousel__scroll_btn__top_motif__line"></span>
        </motion.span>
        <motion.span
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 10, scale: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6, delay: 2.4, type: 'spring' }}
          className="home_carousel__scroll_btn__text"
        >
          Scroll for More
        </motion.span>
        <span className="home_carousel__scroll_btn__bottom_motif">
          <motion.span
            initial={{ y: '-50%', opacity: 0, scale: 0.9 }}
            animate={{ y: 0, opacity: 10, scale: 1 }}
            exit={{ y: '50%', opacity: 0 }}
            transition={{ duration: 0.4, delay: 3 }}
            className="home_carousel__scroll_btn__bottom_motif__line"
          ></motion.span>
          <motion.img
            initial={{ y: '50%', opacity: 0 }}
            animate={{ y: '-8px', opacity: 1 }}
            exit={{ y: '-50%', opacity: 0 }}
            transition={{ duration: 0.4, delay: 3.4 }}
            className="home_carousel__scroll_btn__bottom_motif__icon"
            src={btnArrowIcon}
            alt=""
          />
        </span>
      </motion.button>
      </div>
      </Mobile>
    </div>
  );
}
